import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Text from "../components/Text"

const LivestreamPage = () => {
  const getText = () => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search)
      return `This event will be available at ${urlParams.get('time')} on ${urlParams.get('date')}.`
    } else {
      return 'This event is not available'
    }
  }
  return (
    <Layout noBreadcrumb>
      <Seo title="Livestream" />
      <div className="livestream-info">
        <Text.Header className="mt-5" size="larger" color="var(--text-color)">
          {getText()}
        </Text.Header>
        <Text.Normal>Thank you for your interest and patience!</Text.Normal>
        <button className="green-btn mt-5">
          <Link to='/'>
            <Text.Normal weight="bold" size="large">BACK TO HOME</Text.Normal>
          </Link>
        </button>
      </div>
    </Layout>
  )
}

export default LivestreamPage
